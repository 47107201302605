import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@material-ui/core";

const AlertDialog = (props) => {
  // const [open, setOpen] = React.useState(false);

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography
              variant="h6"
              sx={{
                color: "success.main",
                fontSize: "20px",
              }}
              align="center"
            >
              {props.successMsg}
            </Typography>
            {props.subMsg ? (
              <Typography
                variant="body1"
                sx={{
                  color: "text.primary",
                  fontSize: "14px",
                  pt: 1,
                }}
              >
                {props.subMsg}
              </Typography>
            ) : (
              ""
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            sx={{
              backgroundColor: "background.paper",
              color: "text.primary",
            }}
            onClick={props.handleClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AlertDialog;
