import React, { useEffect } from "react";
import { Box, Typography, Grid, Paper, Button, Container } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { 
  Videocam,
  ScreenShare,
  Mic,
  Message,
  Group,
  Security
} from '@material-ui/icons';
import videoSupportImg from '../../images/video_support.jpg';

const Intro = () => {
  const navigate = useNavigate();
  
  useEffect(() => {
    const navItem = document.getElementsByClassName("iconnect-nav-buttons")[0];
    if (navItem) {
      navItem.style.display = "none";
    }
  }, []);

  const features = [
    {
      icon: <Videocam fontSize="large" color="primary" />,
      title: "Video Support",
      description: "Connect with our support agents through high-quality video calls for personalized assistance"
    },
    {
      icon: <ScreenShare fontSize="large" color="primary" />,
      title: "Screen Sharing",
      description: "Share your screen with our agents to get precise guidance and quick issue resolution"
    },
    {
      icon: <Message fontSize="large" color="primary" />,
      title: "Leave Messages",
      description: "Record video, audio, or screen share messages when agents are unavailable"
    },
    {
      icon: <Group fontSize="large" color="primary" />,
      title: "Expert Support Team",
      description: "Get help from our experienced support agents who can resolve your issues effectively"
    },
    {
      icon: <Mic fontSize="large" color="primary" />,
      title: "Clear Communication",
      description: "Crystal clear audio ensures smooth communication with our support team"
    },
    {
      icon: <Security fontSize="large" color="primary" />,
      title: "Secure Support",
      description: "Your support sessions are private and secure "
    }
  ];

  return (
    <Box sx={{
      minHeight: "100vh",
      backgroundColor: "#f5f5f5"
    }}>
      {/* Hero Section with Split Layout */}
      <Grid container sx={{ minHeight: '600px', bgcolor: 'white' }}>
        {/* Left side - Image */}
        <Grid item xs={12} md={6} sx={{
          position: 'relative',
          minHeight: { xs: '300px', md: '600px' },
          backgroundImage: `url(${videoSupportImg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            width: { xs: '0px', md: '100px' },
            background: 'linear-gradient(to left, #fff, transparent)',
          }
        }} />

        {/* Right side - Content */}
        <Grid item xs={12} md={6} sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          px: { xs: 4, md: 8 },
          py: { xs: 6, md: 8 }
        }}>
          <Typography 
            variant="h2" 
            component="h1" 
            gutterBottom 
            sx={{
              fontWeight: "bold",
              color: '#1976d2',
              fontSize: { xs: '2.5rem', md: '3.5rem' },
              lineHeight: 1.2
            }}
          >
            Welcome to<br />iConnect Advisor 
          </Typography>
          
          <Typography 
            variant="h5" 
            sx={{ 
              mb: 3,
              color: '#333',
              maxWidth: '600px'
            }}
          >
            Get Real-Time Video Support from Our Expert Agents
          </Typography>

          <Typography 
            variant="body1" 
            sx={{ 
              mb: 6,
              color: '#666',
              fontSize: '1.1rem',
              maxWidth: '600px'
            }}
          >
            Experience face-to-face support through video calls and screen sharing.
            Our agents are here to help resolve your issues quickly and effectively.
          </Typography>

          {/* <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
            <Button 
              variant="contained" 
              color="primary" 
              size="large"
              onClick={() => navigate('/join-room')}
              sx={{ 
                fontSize: '1.1rem',
                padding: '12px 32px',
                boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                fontWeight: 'bold'
              }}
            >
              Connect with an Agent
            </Button>
            <Button 
              variant="outlined" 
              color="primary" 
              size="large"
              onClick={() => navigate('/leave-room')}
              sx={{ 
                fontSize: '1.1rem',
                padding: '12px 32px'
              }}
            >
              Leave a Message
            </Button>
          </Box> */}
        </Grid>
      </Grid>

      {/* Features Grid */}
      <Container maxWidth="lg" sx={{ py: 8 }}>
        <Typography variant="h3" align="center" gutterBottom sx={{ 
          color: '#333',
          mb: 6,
          fontWeight: 'bold'
        }}>
          How We Help You
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Paper sx={{
                p: 3,
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                transition: "transform 0.2s",
                backgroundColor: '#fff',
                "&:hover": {
                  transform: "translateY(-5px)",
                  boxShadow: 3
                }
              }}>
                <Box sx={{ mb: 2 }}>
                  {feature.icon}
                </Box>
                <Typography variant="h6" gutterBottom sx={{ 
                  fontWeight: "bold",
                  color: '#333'
                }}>
                  {feature.title}
                </Typography>
                <Typography variant="body1" sx={{ color: '#666' }}>
                  {feature.description}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Call-to-Action Section */}
      <Box sx={{
        textAlign: "center",
        py: 8,
        backgroundColor: "#1976d2",
        color: 'white'
      }}>
        <Container maxWidth="md">
          <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }}>
            Need Help? We're Here for You
          </Typography>
          <Typography variant="h6" sx={{ mb: 4, opacity: 0.9 }}>
            Connect with our support team instantly or leave a message - we'll get back to you quickly.
          </Typography>
        </Container>
      </Box>
    </Box>
  );
};

export default Intro;
