import {
  removeStream,
  setLocalStream,
  setShowLoader,
  toggleCam,
  toggleMic,
  setMic,
  setShowPermissionModal
} from "../slices/roomSlice";
import store from "./../store/index";
import * as wss from "./wss";
import Peer from "simple-peer";
import { setStreams } from "./../slices/roomSlice";
import { has } from "lodash";
let hasCamera = false;
let hasMic = true;

const defaultConstraints = {
  audio: true,
  video: true,
};

const onlyAudioConstraints = {
  audio: true,
  video: false,
};

let localStream;

// Single source of truth for peer connections
const peers = {};

// Function to add an empty video track
function addEmptyVideoTrack(stream) {
  try {
    const canvas = document.createElement("canvas");
    canvas.width = 640;
    canvas.height = 480;
    const canvasStream = canvas.captureStream(30);
    const [videoTrack] = canvasStream.getVideoTracks();
    stream.addTrack(videoTrack);
  } catch (error) {
    console.error("Error occurred when adding empty video track", error);
  }
}

// Function to add an empty audio track
function addEmptyAudioTrack(stream) {
  try {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const oscillator = audioContext.createOscillator();
    const destination = audioContext.createMediaStreamDestination();
    oscillator.connect(destination);
    oscillator.start();
    const [audioTrack] = destination.stream.getAudioTracks();
    stream.addTrack(audioTrack);
  } catch (error) {
    console.error("Error occurred when adding empty audio track", error);
  }
}

export const getLocalPreviewAndInitRoomConnection = async (data) => {
  try {
    // Check if permissions are already granted
    const permissions = await navigator.permissions.query({ name: 'camera' });
    const micPermissions = await navigator.permissions.query({ name: 'microphone' });
    
    // Only show permission modal if permissions are not already granted
    if (permissions.state === 'prompt' || micPermissions.state === 'prompt') {
      store.dispatch(setShowPermissionModal(true));
      return;
    }

    // If permissions are already granted or after user clicks allow, proceed with getting devices
    return await initializeDevicesAndConnection(data);
  } catch (error) {
    console.error("Error checking permissions:", error);
    // If we can't check permissions, show the modal to be safe
    store.dispatch(setShowPermissionModal(true));
    return;
  }
};

// Separate function to handle device initialization and connection
export const initializeDevicesAndConnection = async (data) => {
  return new Promise((resolve, reject) => {
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        // Filter devices to find video input devices (cameras)
        const videoDevices = devices.filter(
          (device) => device.kind === "videoinput"
        );

        const audioDevices = devices.filter(
          (device) => device.kind === "audioinput"
        );

        if (audioDevices.length === 0) {
          console.log("No audio devices found");
          hasMic = false;
          store.dispatch(setMic(false));
        }

        if (videoDevices.length > 0) {
          console.log("Camera found");
          // At least one camera is available
          hasCamera = true;
        } else {
          console.log("No camera found");
          // No camera is available
          hasCamera = false;
        }

        if (!hasMic && !hasCamera) {
          // Neither mic nor camera is available, handle the case here
          // You can create empty tracks and proceed or show a message to the user
          localStream = new MediaStream();
          addEmptyAudioTrack(localStream);
          addEmptyVideoTrack(localStream);
          showLocalPreview(localStream);

          store.dispatch(setShowLoader(false));
          data.isRoomHost
            ? wss.createNewRoom(data)
            : wss.joinRoom(data.roomId, data.identity, data.attendee_token);
          resolve();
          return; // Skip further processing
        }
      })
      .then(() => {
        const constraints = {
          audio: hasMic,
          video: hasCamera,
        };

        navigator.mediaDevices
          .getUserMedia(constraints)
          .then((stream) => {
            store.dispatch(setShowLoader(false));

            localStream = stream;

            if (!hasCamera) {
              addEmptyVideoTrack(localStream); // Add an empty video track if no camera
            }

            if (!hasMic) {
              addEmptyAudioTrack(localStream); // Add an empty audio track if no microphone
            }

            showLocalPreview(stream);

            if (data.onlyWithAudio) {
              toggleVideo();
            }
            data.isRoomHost
              ? wss.createNewRoom(data)
              : wss.joinRoom(data.roomId, data.identity, data.attendee_token);
            resolve();
          })
          .catch((error) => {
            store.dispatch(setShowLoader(false));
            console.error("Error occurred in getting local stream", error);
            reject(error);
          });
      })
      .catch((error) => {
        console.error("Error enumerating devices:", error);
        reject(error);
      });
  });
};

const getConfiguration = () => {
  return {
    iceServers: [
      {
        urls: "stun:stun.l.google.com:19302",
      },
      {
        urls: "turn:3.142.183.117:3478",
        username: "iconnect",
        credential: "Iconnect@123",
      },
    ],
  };
};

export const prepareNewPeerConnection = (connectedUserSocketId, isInitiator) => {
  try {
    const configuration = getConfiguration();

    peers[connectedUserSocketId] = new Peer({
      initiator: isInitiator,
      config: configuration,
      stream: localStream,
    });

    peers[connectedUserSocketId].on("signal", (signal) => {
      const signalData = {
        connectedUserSocketId,
        signal,
      };
      wss.signalPeerData(signalData);
    });

    peers[connectedUserSocketId].on("stream", (stream) => {
      addStream(stream, connectedUserSocketId);
      store.dispatch(setStreams(stream, connectedUserSocketId));
    });
  } catch (error) {
    console.error("Error occurred when preparing new peer connection", error);
  }
};

export const handleSinalingData = (data) => {
  try {
    const { connectedUserSocketId, signal } = data;
    peers[connectedUserSocketId].signal(signal);
  } catch (error) {
    console.error("Error occurred when handling signaling data", error);
  }
};

export const removePeerConnection = (data) => {
  try {
    const { socketId } = data;
    const videoEl = document.getElementById(socketId);
    if (videoEl) {
      const tracks = videoEl.srcObject.getTracks();
      tracks.forEach((t) => t.stop());
      videoEl.srcObject = null;
      videoEl.parentNode.removeChild(videoEl);
      if (peers[socketId]) {
        peers[socketId].destroy();
      }
      delete peers[socketId];
      store.dispatch(removeStream(socketId));
    }

    const { participants, isRoomHost } = store.getState().room;
    if (isRoomHost && participants.length < 3) {
      setTimeout(() => {
        window.close();
      }, 5000);
    }
  } catch (error) {
    console.error("Error occurred when removing peer connection", error);
  }
};

const addStream = (stream, connectedUserSocketId) => {
  // Implementation for adding stream to video element
};

const showLocalPreview = (stream) => {
  store.dispatch(setLocalStream(stream));
};

export const toggleMicrophone = () => {
  const isMicEnabled = localStream.getAudioTracks()[0].enabled;
  const storeLocalStream = store.getState().room.localStream;
  store.dispatch(toggleMic(!isMicEnabled));
  storeLocalStream.getAudioTracks()[0].enabled = !isMicEnabled;
};

export const toggleVideo = () => {
  if (localStream?.getVideoTracks().length > 0) {
    const isVideoEnabled = localStream?.getVideoTracks()[0]?.enabled || false;
    const storeLocalStream = store.getState().room.localStream;
    store.dispatch(toggleCam(!isVideoEnabled));
    storeLocalStream.getVideoTracks()[0].enabled = !isVideoEnabled;
    wss.updateCameraStatus(!isVideoEnabled);
  }
};

export const toggleScreenShare = (isScreenSharingActive, screenSharingStream = null) => {
  wss.notifyRoomParticipantsForScreenShare(isScreenSharingActive);
  if (!isScreenSharingActive) {
    switchVideoTracks(localStream);
  } else {
    switchVideoTracks(screenSharingStream);
  }
};

const switchVideoTracks = (stream) => {
  try {
    for (let socketId in peers) {
      const peer = peers[socketId];
      if (peer && peer.streams[0]) {
        const oldTrack = peer.streams[0].getVideoTracks()[0];
        const newTrack = stream.getVideoTracks()[0];
        if (oldTrack && newTrack) {
          peer.replaceTrack(oldTrack, newTrack, peer.streams[0]);
        }
      }
    }
  } catch (error) {
    console.error("Error occurred when switching video tracks", error);
  }
};

// Function to switch audio track in all peer connections
export const switchAudioTrack = (newTrack) => {
  try {
    // First update the local stream
    const audioTracks = localStream.getAudioTracks();
    if (audioTracks.length > 0) {
      audioTracks.forEach(track => {
        track.stop();
        localStream.removeTrack(track);
      });
    }
    localStream.addTrack(newTrack);

    // Then update all peer connections
    for (let socketId in peers) {
      const peer = peers[socketId];
      if (peer) {
        const senders = peer._pc.getSenders();
        const audioSender = senders.find(sender => sender.track?.kind === 'audio');
        if (audioSender) {
          audioSender.replaceTrack(newTrack);
        }
      }
    }
  } catch (error) {
    console.error('Error switching audio track:', error);
  }
};

// Function to switch video track in all peer connections
export const switchVideoTrack = (newTrack) => {
  try {
    // First update the local stream
    const videoTracks = localStream.getVideoTracks();
    if (videoTracks.length > 0) {
      videoTracks.forEach(track => {
        track.stop();
        localStream.removeTrack(track);
      });
    }
    localStream.addTrack(newTrack);

    // Then update all peer connections
    for (let socketId in peers) {
      const peer = peers[socketId];
      if (peer) {
        const senders = peer._pc.getSenders();
        const videoSender = senders.find(sender => sender.track?.kind === 'video');
        if (videoSender) {
          videoSender.replaceTrack(newTrack);
        }
      }
    }
  } catch (error) {
    console.error('Error switching video track:', error);
  }
};
