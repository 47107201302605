import React, { useState, useEffect } from "react";
import { 
  Box, 
  Button, 
  Drawer, 
  Typography, 
  List, 
  ListItem, 
  ListItemText,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  Divider
} from "@material-ui/core";
import { Settings, Close } from "@material-ui/icons";
import { useSelector } from "../../../../store";
import * as webRTCHandler from "../../../../utils/webRTCHandler";

const SettingsButton = () => {
  const [open, setOpen] = useState(false);
  const [audioDevices, setAudioDevices] = useState([]);
  const [videoDevices, setVideoDevices] = useState([]);
  const [selectedAudioDevice, setSelectedAudioDevice] = useState("");
  const [selectedVideoDevice, setSelectedVideoDevice] = useState("");
  const { localStream } = useSelector((state) => state.room);

  useEffect(() => {
    if (open) {
      getDevices();
    }
  }, [open]);

  useEffect(() => {
    // Add device change listener
    navigator.mediaDevices.addEventListener('devicechange', getDevices);
    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', getDevices);
    };
  }, []);

  const getDevices = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      
      const audioInputs = devices.filter(device => device.kind === 'audioinput');
      const videoInputs = devices.filter(device => device.kind === 'videoinput');
      
      setAudioDevices(audioInputs);
      setVideoDevices(videoInputs);

      // Set initial selected devices if stream exists
      if (localStream) {
        const currentAudioTrack = localStream.getAudioTracks()[0];
        const currentVideoTrack = localStream.getVideoTracks()[0];

        if (currentAudioTrack && !selectedAudioDevice) {
          setSelectedAudioDevice(currentAudioTrack.getSettings().deviceId);
        }
        if (currentVideoTrack && !selectedVideoDevice) {
          setSelectedVideoDevice(currentVideoTrack.getSettings().deviceId);
        }
      }
    } catch (error) {
      console.error('Error getting devices:', error);
    }
  };

  const handleAudioDeviceChange = async (deviceId) => {
    try {
      // Get new stream with selected audio device
      const newStream = await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: { exact: deviceId } },
        video: false
      });

      if (localStream) {
        const newAudioTrack = newStream.getAudioTracks()[0];
        
        // Update the track in WebRTC connections first
        await webRTCHandler.switchAudioTrack(newAudioTrack);
        setSelectedAudioDevice(deviceId);
      }
    } catch (error) {
      console.error('Error switching microphone:', error);
    }
  };

  const handleVideoDeviceChange = async (deviceId) => {
    try {
      const newStream = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: { deviceId: { exact: deviceId } }
      });

      if (localStream) {
        const newVideoTrack = newStream.getVideoTracks()[0];
        
        // Update the track in WebRTC connections first
        await webRTCHandler.switchVideoTrack(newVideoTrack);
        setSelectedVideoDevice(deviceId);
      }
    } catch (error) {
      console.error('Error switching camera:', error);
    }
  };

  return (
    <Box>
      <Button
        onClick={() => setOpen(true)}
        sx={{
          color: "white",
          boxShadow: "none",
        }}
        variant="contained"
      >
        <Settings />
      </Button>

      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
      >
        <Box sx={{ width: 350, p: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6">Settings</Typography>
            <IconButton onClick={() => setOpen(false)}>
              <Close />
            </IconButton>
          </Box>

          <Divider sx={{ mb: 2 }} />

          <List>
            <ListItem>
              <Box sx={{ width: '100%' }}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>Microphone</Typography>
                <FormControl fullWidth>
                  <Select
                    value={selectedAudioDevice}
                    onChange={(e) => handleAudioDeviceChange(e.target.value)}
                    variant="outlined"
                    size="small"
                  >
                    {audioDevices.map((device) => (
                      <MenuItem key={device.deviceId} value={device.deviceId}>
                        {device.label || `Microphone ${audioDevices.indexOf(device) + 1}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </ListItem>

            <ListItem>
              <Box sx={{ width: '100%' }}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>Camera</Typography>
                <FormControl fullWidth>
                  <Select
                    value={selectedVideoDevice}
                    onChange={(e) => handleVideoDeviceChange(e.target.value)}
                    variant="outlined"
                    size="small"
                  >
                    {videoDevices.map((device) => (
                      <MenuItem key={device.deviceId} value={device.deviceId}>
                        {device.label || `Camera ${videoDevices.indexOf(device) + 1}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};

export default SettingsButton;


